<template>
    <div class="detail">
        <div class="detail-title">
            <img
                class="question"
                src="@/assets/images/mobile/container/questions/question.png"
            />
            <span class="detail-title__txt">{{ title }}</span>
        </div>

        <div class="detail-content">
            <div class="detail-answer">答：</div>
            <div class="detail-list">
                <div
                    v-for="(detail, idx) in details"
                    :key="idx"
                    class="detail-item"
                >
                    <div class="detail-txt">
                        {{ detail.text }}
                    </div>
                    <div class="detail-img">
                        <img v-if="detail.img" :src="detail.img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            title: "",
            details: [],
            id: 5
        };
    },

    methods: {
        getQuestionDetail(id) {
            this.$api
                .request({
                    url: "xingwawa/index.php",
                    method: "get",
                    params: {
                        m: "gm",
                        a: "get_faq_detail",
                        id
                    }
                })
                .then(res => {
                    let { detail, title } = res.ret;
                    detail = JSON.parse(decodeURIComponent(detail));
                    title = decodeURIComponent(title);

                    this.details = detail;
                    this.title = title;
                });
        }
    },

    mounted() {
        this.getQuestionDetail(this.$route.query.id);

        console.log(1)
    }
};
</script>

<style lang="less" scoped>
.detail {
    color: #383838;
    font-size: calc(100vw / 1080 * 42);
    padding-top: calc(100vw / 1080 * 25);
    padding-left: calc(100vw / 1080 * 60);

    &-content {
        display: flex;
    }

    &-title {
        color: #495e8f;
        font-size: calc(100vw / 1080 * 46);
        display: flex;
        align-items: center;
        margin-bottom: calc(100vw / 1080 * 60);

        .question {
            width: calc(100vw / 1080 * 54);
            margin-right: calc(100vw / 1080 * 24);
        }
    }

    &-answer {
        color: #444d84;
        width: calc(100vw / 1080 * 80);
    }

    &-list {
        text-align: left;
        line-height: 1.358;
    }

    &-txt {
        margin-bottom: calc(100vw / 1080 * 30);
        padding-right: calc(100vw / 1080 * 95);
        text-align: justify;
    }

    &-img {
        margin-bottom: calc(100vw / 1080 * 20);

        img {
            width: calc(100vw / 1080 * 320);
        }
    }
}
</style>
